import { BaseElement, html, css, formCss } from 'Components';
import { Fetcher, UrlParams, Lang, ProcessNextTick } from 'Utils';

import './edit.js';
import nodeTypes from './types.js';
import 'Components/business/select-permissions.js';
import './menu.js';

class CustomersTree extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        :host {
          font-family: Calibri;
        }

        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .search sl-input {
          width:100%;
        }

        .search sl-button {
          width:40px;
          height:30px;
        }

        .tree_item_img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 4px;
          left: 0px;
        }

        .icons_right {
          display:flex;
          justify-content:end;
          align-items:center;
          width:100%;
          gap:4px;
          padding-right:25px;
        }

        sl-tree {
          /*background-color: var(--sl-color-neutral-100);*/
        }

        sl-tree-item {
          --indent-size:15px;
          --indent-guide-width:1px;
        }

        sl-tree-item::part(label) {
          font-family:Calibri;
          font-size:1rem;
          color:var(--text-color);
          width:100%;
          padding-left:20px;
          position:relative;
          line-height:1.5em;
          display: flex;
          justify-content: stretch;
        }

        sl-tree-item span {
          display:block;
          white-space:nowrap;
        }

        sl-tree-item::part(item) {
          font-family:Calibri;
          color:var(--text-color);
          width:100%;
          padding-left:0px;
          position:relative;
          border-bottom:1px solid var(--sl-color-neutral-300);
        }

        sl-tree-item::part(expand-button) {
          padding: 3px;
          padding-right: 7px;
          top: 3px;
          position: relative;
        }

        sl-tree-item .icons_left m-icon {
          font-size:18px;
          padding-left:4px;
          padding-right:4px;
          padding-top:2px;
          position:absolute;
          left:-5px;
          top:1px;
        }

        sl-tree-item.organization::part(label) {
          font-weight:bold;
        }

        sl-tree-item.affiliate::part(label) {
          font-weight:bold;
        }

        sl-tree-item.entity::part(label) {
          font-weight:bold;
        }

        sl-tree-item.trash::part(label) {
          color:var(--sl-color-danger-600);
        }

        sl-tree-item.disabled .icons_left m-icon {
          color:var(--sl-color-orange-400);
        }

        sl-tree-item .node_title {
          min-width:200px;
        }

        sl-tree-item .node_title:hover {
          color:var(--sl-color-primary-500);
        }

        sl-tree-item.invalid::part(label) {
          color:red;
          text-decoration: line-through;
        }

        sl-tree-item .tree_menu {
          position:absolute;
          right:10px;
          padding-top:2px;
          max-height:15px;
          max-width:15px;
        }

        /*
        sl-tree-item.hide-during-drag {
          --indent-guide-width:0px;
          --indent-size:0px;
          --indent-guide-offset:0px;
        }

        sl-tree-item.hide-during-drag::part(indentation) {
          opacity:0;
        }

        sl-tree-item.hide-during-drag::part(expand-button) {
          display:none;
        }

        sl-tree-item.hide-during-drag::part(checkbox) {
          display:none;
        }
        */

        .bold::part(label) {
          /*font-weight:bold;*/
        }

        sl-tree-item.drag-over {
          outline:1px solid red;
        }

        .toolbar {
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin-top:2px;
        }

        .toolbar > * {
          width:100%;
          display:flex;
          gap:10px;
          justify-content:flex-end;
        }

        .toolbar sl-select {
          width:190px;
        }

        .toolbar sl-option m-icon {
          font-size:1em;
        }

        sl-menu-item {
          padding: 0;
          cursor: pointer;
          font-size:0.2em;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

        sl-menu-item::part(label) {
          padding:0px;
          font-size:0.9em;
          font-family: Calibri;
          line-height:initial;
        }

        .hidden {
          display:none;
        }

        .hidden_visibility: {
          visibility:hidden;
        }

        .gray::part(label) {
          opacity:0.6;
        }

        mark {
          background-color: yellow;
        }

        sl-radio-group::part(form-control-label) {
          font-size:1em;
        }

        .hidden_label m-icon {
          padding-top:2px;
        }

        .hidden_label::part(base) {
          font-size: 0.6em;
          height: calc(var(--sl-input-height-small)* 0.6);
          line-height: initial;
          padding: 0 var(--sl-spacing-x-small);
          font-weight:normal;
        }

        .hidden_label div {
          /*max-width: 0;*/
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          transition: max-width 0.3s;
        }

        .hidden_label:hover div {
          max-width: 100px; /* Une valeur suffisamment grande pour couvrir la largeur maximale attendue */
        }

        modal-dialog m-icon {
          font-size:1.5em;
        }

        .flex {
          display: flex;
          align-items: center;
          width: 100%;
          gap:20px;
          justify-content: space-around;
        }

        .flex box-styled{
          width: 100%;
        }
      `
    ];
  }

  static get properties() {
    return {
      displayOptions: { type: Array },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            expandAll:'Expand all',
            collapseAll:'Collapse all',
            expand:'Expand',
            collapse:'Collapse',
            add:'Add',
            remove:'Delete',
            archive:'Archive',
            edit:'Edit',
            display:{
              title:'Display ...',
              roles:'Roles',
              permissions:'Permissions',
              validations:'Validations',
            }
          },
        },
        french:{
          translation: {
            expandAll:'Tout déplier',
            collapseAll:'Tout replier',
            expand:'Déplier',
            collapse:'Replier',
            add:'Ajouter',
            archive:'Archiver',
            remove:'Supprimer',
            edit:'Editer',
            display:{
              title:'Afficher ...',
              roles:'Rôles',
              permissions:'Permissions',
              validations:'Validations',
            }
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.apiEndpoint = 'private/admin/customers/tree';
    this.apiEndpointGrp = 'private/admin/customers/grp';
    this.selectedItem = null;
    this.selectedTreeItem = null;
    this.hasRoot = false;
    this.nodeTypes = null;
    this.onKeycloakBrowserDragStart = this.onKeycloakBrowserDragStart.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.doItemMove = this.doItemMove.bind(this);
    this.eventUpdated = 'customer-node-updated';
    this.firstLoad = true;
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('keycloak-browser-drag-start', this.onKeycloakBrowserDragStart);
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  async disconnectedCallback() {
    super.disconnectedCallback();
    this.uninstallKeyboardHandler();
    this.uninstallNodeUpdateHandler();
    window.removeEventListener('keycloak-browser-drag-start', this.onKeycloakBrowserDragStart);
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  async firstUpdated() {
    super.firstUpdated();
    this.nodeTypes = nodeTypes.getNodeTypes();
    await this.refreshData();
    this.tree = this.qs('sl-tree');
    this.modalConfirmDelete = this.qs('#modal-customer-confirm-delete');
    this.modalConfirmArchive = this.qs('#modal-customer-confirm-archive');
    this.modalConfirmRestore = this.qs('#modal-customer-confirm-restore');
    this.modalConfirmMove = this.qs('#modal-customer-confirm-move');
    this.nodeEdit = this.parentNode.querySelector('customer-node-edit');
    this.installKeyboardHandler();
    this.installUnselectAllClickHandler();
    this.installNodeUpdateHandler();
    this.expanded = [];
    const expNodes = localStorage.getItem('customerTreeExpandedNodes');
    if (expNodes) {
      this.expandedNodes = JSON.parse(expNodes);
      this.treeRestoreExpanded();
    }
  }

  async expandRootNode() {
    const root = this.shadowRoot.querySelector('sl-tree-item.root');
    if (root) root.expanded = true;
  }

  async installUnselectAllClickHandler() {
    this.toolbarContainer = this.shadowRoot.querySelector('.toolbar');
    this.toolbarContainer.addEventListener('click', (ev) => {
      if (ev.target.className === 'toolbar') {
        // reset selection
        if (this.selectedTreeItem) {
          this.selectedTreeItem.selected = false;
          this.selectedItem = null;
        }
      }
    });
  }

  async onLanguageChanged() {
    this.nodeTypes = nodeTypes.getNodeTypes();
    this.requestUpdate();
  }

  async onKeycloakBrowserDragStart(ev) {
    this._log.debug('onKeycloakBrowserDragStart', ev.detail);
    this.floatingDiv = document.querySelector('#dragImage');
    this.kcBrowserDraggedItems = ev.detail;
  }

  async onKeyDown(ev) {  
    ev.stopPropagation();
    ev.preventDefault();

    if (ev.key === '+') {
      await this.treeCollapseToggle({ recursive:false, expand:true, root: this.selectedTreeItem || this.tree });
      await this.treeStoreExpanded();
    } else if (ev.key === '-') {
      await this.treeCollapseToggle({ recursive:false, expand:false, root: this.selectedTreeItem || this.tree });
      await this.treeStoreExpanded();
    } else if (ev.key === '*') {
      await this.treeCollapseToggle({ recursive:true, expand:!this.tree.expanded, root: this.tree });
      await this.treeStoreExpanded();
    } else if (ev.key === 'Delete') {
      //@FIXME: je suis dans la poubelle (delete) ou pas (archive ?)
      await this.showConfirmDelete(ev);
    }
  }
  
  async installKeyboardHandler() {
    this.onKeyDown = this.onKeyDown.bind(this);
    if (this.tree) {
      this.tree.addEventListener('keydown', this.onKeyDown);
    }
  }

  async uninstallKeyboardHandler() {
    if (this.tree) {
      this.tree.removeEventListener('keydown', this.onKeyDown);
    }
  }

  async installNodeUpdateHandler() {
    this.onNodeUpdated = this.onNodeUpdated.bind(this);
    window.addEventListener(this.eventUpdated, this.onNodeUpdated);
  }

  async uninstallNodeUpdateHandler() {
    window.removeEventListener(this.eventUpdated, this.onNodeUpdated);
  }

  async treeStoreExpanded() {
    if (!this.tree) return;
    this.expandedNodes = [];
    this.tree.querySelectorAll('sl-tree-item').forEach(treeItem => {
      if (treeItem.expanded) {
        this.expandedNodes.push(treeItem.item._id);
      }
    });

    // store expanded nodes in local storage
    localStorage.setItem('customerTreeExpandedNodes', JSON.stringify(this.expandedNodes));
  }

  async treeRestoreExpanded() {
    this.tree = this.shadowRoot.querySelector('sl-tree');
    if (!this.tree) return;
    this.tree.querySelectorAll('sl-tree-item').forEach(async treeItem => {
      treeItem.classList.remove('hidden');
      treeItem.classList.remove('gray');

      // remove <mark> tags
      const slot = treeItem.shadowRoot.querySelector('slot[part="label"]');
      if (slot) {
        const nodes = slot.assignedNodes({ flatten: true });
        nodes.forEach(node => {
          if (node.tagName === 'SPAN' && node.className === 'title') {
            node.innerHTML = node.textContent;
          }
        });
      }

      if (this.expandedNodes) {
        treeItem.expanded = this.expandedNodes.includes(treeItem.item._id);
        if (this.selectedItem && treeItem.item._id === this.selectedItem._id) {
          treeItem.selected = true;
          this.selectedTreeItem = treeItem;
          this.selectedItem = treeItem.item;
        }
      }
    });
  }

  async treeCollapseToggle( opts ) {
    opts.root.expanded = opts.expand;
    let items;
    if (opts.recursive) {
      items = opts.root.querySelectorAll('sl-tree-item');
    } else {
      items = opts.root.querySelectorAll(':scope > sl-tree-item');
    }
    for (const item of items) item.expanded = opts.expand;
  }

  async sendEvent(detail = {}) {
    const event = new CustomEvent(this.eventUpdated, { detail });  
    window.dispatchEvent(event);
  }

  async onNodeUpdated(ev) {
    this._log.debug('onNodeUpdated', ev.detail);
    this.treeStoreExpanded();

    if (ev.detail._id) {
      await this.refreshData();
      await this.updateComplete;
      const qs = `sl-tree-item[data-id="${ev.detail._id}"]`;
      setTimeout(() => {
        const item = this.qs(qs);
        if (item) {
          item.selected = true;
          item.expanded = true;
          this.selectedTreeItem = item;
          this.selectedItem = item.item;
          // recursively expand all parents
          let parent = item.parentNode;
          while (parent.tagName === 'SL-TREE-ITEM') {
            parent.expanded = true;
            parent = parent.parentNode;
          }

          this.treeStoreExpanded();
        }
      }, 100);
    } else {
      await this.refreshData();
    }

    this.treeRestoreExpanded();
  }

  async showTreeMenu(ev) {
    ProcessNextTick(() => {
      this.contextMenu = this.contextMenu || this.shadowRoot.querySelector('customers-tree-menu[floating]');
      if (this.contextMenu) this.contextMenu.show(ev);
    });
  }

  async showContextMenu(ev) {
    let treeItem = ev.target;
    if (ev.target.tagName !== 'SL-TREE-ITEM') {
      treeItem = ev.target.closest('sl-tree-item');
    }
    
    ev.preventDefault();
    ev.stopPropagation();

    this.emptySelection();
    treeItem.selected = true;

    this.selectedTreeItem = treeItem;
    this.selectedItem = treeItem.item;

    this.showTreeMenu(ev);
  }

  async showConfirmMove(draggedItem, targetItem) {
    this.modalConfirmMove.show();

    const dragged = this.modalConfirmMove.querySelector('#dragged');
    dragged.innerHTML = this.getNodeTitle(draggedItem);
    dragged.micon = this.nodeTypes[draggedItem.nodeType].micon;

    const targeted = this.modalConfirmMove.querySelector('#targeted');
    targeted.innerHTML = this.getNodeTitle(targetItem);
    targeted.micon = this.nodeTypes[targetItem.nodeType].micon;

    this.modalConfirmMove.draggedItem = draggedItem;
    this.modalConfirmMove.targetItem = targetItem;
  }

  async showConfirmMoveKc(target) {
    let treeItem = target.closest('sl-tree-item');
    while (!this.nodeTypes[treeItem.item.nodeType].kcgroup) {
      treeItem = treeItem.parentElement.closest('sl-tree-item');
    }

    const keycloakGroupDst = treeItem.item.name;
    if (!keycloakGroupDst) {
      throw new Error('No keycloak group found');
    }

    const nodeType = this.nodeTypes[treeItem.item.nodeType].name;

    this.modalConfirmMoveKc = this.modalConfirmMoveKc || document.querySelector('#modal-customer-confirm-move-kc') || this.shadowRoot.querySelector('#modal-customer-confirm-move-kc');
    this.modalConfirmMoveKc.querySelector('sl-radio-group').label = html`Déplacer ou associer vers <b>"${nodeType} ${keycloakGroupDst}"</b> ?`;
    this.modalConfirmMoveKc.show();
    this.modalConfirmMoveKc.group = treeItem.item;
  }

  async showConfirmDelete(ev) {
    if (ev?.target?.disabled) return;
    
    const nodeType = this.nodeTypes[this.selectedItem.nodeType];
    if (nodeType?.isKcGroup) {
      this.modalConfirmDelete.querySelector('.warning').innerHTML = `
        <box-styled variant="warning">
          <div style="font-size:0.9em">
            <a href="${KC_PUBLIC_URL}/admin/master/console/#/sysdreamio/groups/${this.selectedItem.kc_id}" target="kc">Le groupe Keycloak associé</a>
          va également être supprimé.<br/>Les utilisateurs vont devenir des "orphelins" sans groupe.
          </div>
        </box-styled>
      `;
    }

    if (nodeType?.isKcUser) {
      this.modalConfirmDelete.querySelector('.warning').innerHTML = `
        <box-styled variant="warning">
          <div style="font-size:0.9em">
            <a href="${KC_PUBLIC_URL}/admin/master/console/#/sysdreamio/users/${this.selectedItem.kc_id}/settings" target="kc">Le compte utilisateur Keycloak associé</a>
          va également être supprimé. L'utilisateur<br/>ne pourra plus se connecter à la plateforme. Vous ne pourrez pas le restaurer.
          </div>
        </box-styled>
      `;
    }
    
    this.modalConfirmDelete.show();
    this.modalConfirmDelete.qs('sl-button[variant="danger"]').disabled = true;

    this.modalConfirmDelete.qs('sl-switch').addEventListener('sl-change', (ev) => {
      this.modalConfirmDelete.qs('sl-button[variant="danger"]').disabled = !ev.target.checked;
    });
  }

  async showConfirmArchive(ev) {
    if (ev?.target?.disabled) return;
    const warning = this.modalConfirmArchive.querySelector('.warning');

    if (this.selectedItem.nodeType === 'account') {
      warning.innerHTML = `
        <br/>
        <box-styled variant="warning">
          <div style="font-size:0.8em">
            Le compte utilisateur va être désactivé et ne pourra plus se connecter à la plateforme.<br/>
            L'utilisateur va être placé dans le dossier Trash et pourra être restauré ultérieurement si nécessaire.
          </div>
        </box-styled>
      `;
    }

    if (this.nodeTypes[this.selectedItem.nodeType]?.isKcGroup) {
      warning.innerHTML = `
        <br/>
        <box-styled variant="warning">
          <div style="font-size:0.8em">
            bla
          </div>
        </box-styled>
      `;
    }

    this.modalConfirmArchive.show();
    this.modalConfirmArchive.qs('sl-button[variant="danger"]').disabled = true;
    this.modalConfirmArchive.qs('sl-switch').addEventListener('sl-change', (ev) => {
      this.modalConfirmArchive.qs('sl-button[variant="danger"]').disabled = !ev.target.checked;
    });
  }

  async showConfirmRestore(ev) {
    if (ev?.target?.disabled) return;
    this.modalConfirmRestore.show();
  }

  async showAddItem(id) {
    UrlParams.clean();
    this.nodeEdit.parent = this;
    this.nodeEdit.item = null;
    await this.nodeEdit.updateComplete;
    this.nodeEdit.item = {
      nodeType:id,
      parentId:this.selectedItem ? this.selectedItem._id : null
    }
  }

  async showEditItem(ev) {
    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      if (ev.target?.tagName === 'SL-TREE-ITEM') return;
    }
    this.nodeEdit.parent = this;
    this.nodeEdit.item = null;
    await this.nodeEdit.updateComplete;
    this.nodeEdit.item = this.selectedItem;
  }

  async showDropForbidden(ev) {
    if (this.floatingDiv) {
      this.floatingDiv.classList.remove('allowed');
      this.floatingDiv.classList.add('forbidden');
      //ev.dataTransfer.setDragImage(this.floatingDiv, 0, 0);
    }
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = 'none';
    this.dropAllowed = false;
  }

  async showDropAllowed(ev) {
    if (this.floatingDiv) {
      this.floatingDiv.classList.remove('forbidden');
      this.floatingDiv.classList.add('allowed');
      //ev.dataTransfer.setDragImage(this.floatingDiv, 0, 0);
    }
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = 'move';
    this.dropAllowed = true;
  }

  async doItemDelete() {
    this.modalConfirmDelete.loadingButtons();
    const response = await Fetcher.delete(`${this.apiEndpoint}/${this.selectedItem._id}`);
    this.modalConfirmDelete.resetButtons();
    this.modalConfirmDelete.hide();

    if (response?.ok) {
      this.sendEvent();
    }
  }

  async doItemArchive() {
    this.modalConfirmArchive.loadingButtons();
    const response = await Fetcher.put(`${this.apiEndpoint}/${this.selectedItem._id}/trash`);

    this.modalConfirmArchive.resetButtons();
    this.modalConfirmArchive.hide();

    if (response?.ok) {
      this.sendEvent({ _id: this.selectedItem._id });
    }
  }

  async doItemRestore() {
    this.modalConfirmRestore.loadingButtons();
    const response = await Fetcher.put(`${this.apiEndpoint}/${this.selectedItem._id}/restore`);

    this.modalConfirmRestore.resetButtons();
    this.modalConfirmRestore.hide();

    if (response?.ok) {
      this.sendEvent({ _id: this.selectedItem._id });
    }
  }

  async doItemMove() {
    this.modalConfirmMove.loadingButtons();
    this.updateTreeStructure(this.modalConfirmMove.draggedItem, this.modalConfirmMove.targetItem);
    this.modalConfirmMove.resetButtons();
    this.modalConfirmMove.hide();
    this.requestUpdate();
  }

  async doItemMoveKc() {
    this.modalConfirmMoveKc.loadingButtons();

    //console.log('doItemMoveKc group', this.modalConfirmMoveKc.group);
    //console.log('doItemMoveKc items', this.kcBrowserDraggedItems);

    const op = this.modalConfirmMoveKc.querySelector('sl-radio-group').value;
    const url = `${this.apiEndpointGrp}/${this.modalConfirmMoveKc.group._id}/${op}`;
    await Fetcher.post(url, { users: this.kcBrowserDraggedItems } );

    this.modalConfirmMoveKc.resetButtons();
    this.modalConfirmMoveKc.hide();
  }

  async emptySelection() {
    if (this.selectedTreeItem) {
      this.selectedTreeItem.selected = false;
      this.selectedTreeItem = null;
      this.selectedItem = null;
    }
  }

  async itemsPropagateAttributes(items) {
    const itemsById = {};
    for (const item of items) {
      itemsById[item._id] = item;
    }

    /*
    function debugMe(nodeId, debug) {
      return itemsById[nodeId].email_primary?.match(/soisson/) || debug;
    }

    function getName(nodeId) {
      return `${itemsById[nodeId].nodeType} ${itemsById[nodeId].name || itemsById[nodeId].firstname}`;
    }
    */

    function mergeAttributes(nodeId, data, debug) {
        const node = data[nodeId];
        if (!node) return data;
        const parentId = node.parentId;
        const merged = {};

        // Copy all io* attributes from the current node
        for (const key in node) {
          if (key.startsWith('iop')) {
              merged[key] = node[key];
          }
        }

        //if (debugMe(nodeId, debug)) { console.log(getName(nodeId), 'before merged', { ...merged }); }

        // Recursively merge from parent if available
        if (parentId) {
          //if (debugMe(nodeId, debug)) { console.log(getName(nodeId), 'found parent', parentId); debug = true; }
          const parentMerged = mergeAttributes(parentId, data, debug);
          for (const key in parentMerged) {
            if (key.startsWith('io')) {
              if (merged[key] === '' || merged[key] === undefined || merged[key] === null) {
                merged[key] = parentMerged[key];
              }
            }
          }
        }

        //if (debugMe(nodeId, debug)) { console.log(getName(nodeId), 'after merge', { ...merged }); }

        // remove null, undefined and empty strings from merged
        for (const key in merged) {
          if (merged[key] === '' || merged[key] === undefined || merged[key] === null) {
            delete merged[key];
          }
        }

        node.permissions_merged = merged;
        return merged;
    }

    for (const nodeId in itemsById) {
      mergeAttributes(nodeId, itemsById);
    }
  }

  async reload() {
    await this.refreshData();
    this.treeRestoreExpanded();
  }

  async refreshData() {
    this.loading = true;
    this.loader = this.loader || this.shadowRoot.querySelector('sl-progress-bar');
    this.loader.style.visibility = 'visible';
    const response = await Fetcher.get(this.apiEndpoint);
    if (!response) return;
    const items = response.data;

    // look for nodeType "root" and place it at the first position
    const rootIndex = items.findIndex(item => item.nodeType === 'root');
    if (rootIndex !== -1) {
      const root = items.splice(rootIndex, 1);
      items.unshift(root[0]);
      this.hasRoot = true;
    }

    this.itemsPropagateAttributes(items);
    this.items = items;
    this.loading = false;
    this.loader.style.visibility = 'hidden';
    this.firstLoad = false;
    this.requestUpdate();
  }

  renderTreeItemIcon(item) {
    if (item.favicon_url) {
      return html`<img src="${item.favicon_url}" class="tree_item_img">`;
    }

    const icon = this.nodeTypes[item.nodeType]?.micon || 'question_mark';

    if (icon) {
      return html`<m-icon name="${icon}"></m-icon>`;
    } else {
      this._log.warn('No icon for nodeType', item.nodeType);
      return '';
    }
  }

  isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node instanceof ShadowRoot ? node.host : node.parentNode;
    }
    return false;
  }

  dadOnDragStart(ev, item) {
    ev.stopPropagation();

    this.draggedItem = item;
    this.draggedElement = ev.currentTarget;
          
    const d = this.floatingDiv = document.createElement('div');
    d.className = 'dragged';

    const i = document.createElement('m-icon');
    i.name = this.renderTreeItemIcon(item);
    //i.style.zoom = '75%';
    i.style.marginRight = '5px';
    d.appendChild(i);

    const s = document.createElement('span');
    s.textContent = this.getNodeTitle(item);
    s.style.paddingTop = '2px';
    s.style.paddingRight = '2px';
    d.appendChild(s);
    
    document.body.appendChild(this.floatingDiv);
    ev.dataTransfer.effectAllowed = 'move';
    ev.target.addEventListener('dragend', this.dadOnDragEnd.bind(this));

    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // Image transparente de 1x1 pixel
    ev.dataTransfer.setDragImage(img, 0, 0);
  }

  dadOnDragEnd(ev) {
    ev.target.removeEventListener('dragend', this.dadOnDragEnd);
    ev.target.classList.remove('hide-during-drag');
    this.floatingDiv?.remove();
    this.draggedItem = null;
    this.draggedElement = null;
  }

  dadOnDragOver(ev) {
    ev.preventDefault(); // Nécessaire pour permettre le dépôt

    if (!this.floatingDiv) {
      this.floatingDiv = document.body.querySelector('#dragImage');
    }

    if (this.floatingDiv.id !== 'dragImage') {
      this.floatingDiv.style.left = `${ev.clientX - this.floatingDiv.offsetWidth/2}px`; // +10 pour un petit décalage
      this.floatingDiv.style.top = `${ev.clientY + 10}px`;
    }
    //ev.dataTransfer.setDragImage(this.floatingDiv, 0, 0);

    let target = ev.target;

    if (target.tagName !== 'SPAN') {
      this.showDropForbidden(ev);
      return;
    }

    while (target && target.tagName !== 'SL-TREE-ITEM') {
      target = target.parentElement;
    }

    const draggedItem = this.draggedElement?.item;

    if (draggedItem) {
      // avoid drag on myself
      if (draggedItem._id !== target.item._id) {
        // avoid drag on parent
        if (draggedItem.parentId !== target.item._id) {
          // avoid drag on children
          if (!this.isDescendant(this.draggedElement, target)) {
            // avoid drag on nodeType not accepting children
            if (this.nodeTypes[target.item.nodeType]?.noChilds) {
              this.showDropForbidden(ev);
              return;
            }

            // allow drop
            this.showDropAllowed(ev);
            return;
          }
        }
      }
    } else {
      // draggedItem is coming from keycloak-browser
      if (!this.nodeTypes[target.item.nodeType].noChilds) {
        this.showDropAllowed(ev);
        return;
      }
    }

    this.showDropForbidden(ev);
  }

  dadOnDrop(ev, targetItem) {
    this.floatingDiv.remove();
    this.floatingDiv = null;

    this._log.debug('dadOnDrop', ev, targetItem);
    if (this.dropAllowed === false) return;
    if (ev.target.tagName !== 'SPAN') return;
    ev.preventDefault();
    ev.stopPropagation();
    
    if (this.draggedItem) {
      this.showConfirmMove(this.draggedItem, targetItem);
    } else {
      this.showConfirmMoveKc(ev.target, targetItem);
    }
  }

  async updateTreeStructure(movedItem, newParent) {
    const itemIndex = this.items.findIndex(item => item._id === movedItem._id);
    if (itemIndex !== -1) {
      this.items[itemIndex].parentId = newParent._id;
    }

    await Fetcher.put(`${this.apiEndpoint}/${movedItem._id}/move`, { parentId:newParent._id, nodeType:movedItem.nodeType });
  }

  async updateCollapseButton() {
    const textEl = this.buttonToggleCollapse.querySelector('div');
    if (this.selectedTreeItem) {
      textEl.innerHTML = this.selectedTreeItem.expanded ? this._tl('collapse') : this._tl('expand');
    } else {
      textEl.innerHTML = this.tree.expanded ? this._tl('collapseAll') : this._tl('expandAll');
      this.buttonToggleCollapse.querySelector('m-icon').name = this.tree.expanded ? 'unfold_less' : 'unfold_more';
    }
  }

  onTreeSelectionChange(ev) {
    this.selectedTreeItem = ev.detail.selection[0];
    this.selectedItem = this.selectedTreeItem.item;
    if (!this.selectedTreeItem.expanded) {
      this.selectedTreeItem.expanded = true;
    }
    //this.updateCollapseButton();
  }

  getNodeTitle(item) {
    //this._log.debug('getNodeTitle', item);
    const nodeType = this.nodeTypes[item.nodeType];
    if (!nodeType) {
      // item.email is coming from this.kcBrowserDraggedItem
      return item.email || item.name || item.value || item._id;
    }

    const fields = nodeType.nodeTitle;
    if (!fields) {
      return item.name || item.value || nodeType.name;
    }

    let str = fields.map(field => {
      if (item[field]) return `${item[field]}`;
    });

    // remove undefined values
    str = str.filter(Boolean);
    str = str.join(' ');
    //str = this.sanitizeHTML(str);
    return str;
  }

  sanitizeHTML(str) {
    if (typeof str !== 'string') return str;
    return str.replace(/&/g, '&amp;')
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/"/g, '&quot;')
              .replace(/'/g, '&#039;')
              .replace(/`/g, '&#x60;');
  }

  searchOnInput(ev) {
    const target = ev.target;
    clearTimeout(this.searchTimeout);   
    this.searchTimeout = setTimeout(() => {
      this.q = target.value.toLowerCase().trim();

      if (!this.q) {
        this.treeRestoreExpanded();
        return;
      }
      
      if (!this.tree) return;

      const items = this.tree.querySelectorAll('sl-tree-item');
      if (!items) return;

      items.forEach(treeItem => {
        let found = false;
        
        //console.log('analyze', treeItem.item.name || treeItem.item.firstname);

        // loop trought all item attribut values to find a match
        for (const value of Object.values(treeItem.item)) {
          //console.log('==> look', this.q,'in', value);
          if (value && value.toString().toLowerCase().indexOf(this.q) >= 0) {
            //console.log('==> found', this.q,'in', treeItem);
            found = true;
            break;
          }
        }

        if (found) {
          // visible
          treeItem.classList.remove('hidden');
          treeItem.classList.remove('gray');
          // highlight matching part
          const slot = treeItem.shadowRoot.querySelector('slot[part="label"]');
          if (slot) {
            const nodes = slot.assignedNodes({ flatten: true });
            nodes.forEach(node => {
              if (node.tagName === 'SPAN' && node.className === 'title') {
                const originalText = node.textContent;
                const regex = new RegExp(`(${this.q})`, 'gi');
                const highlightedText = originalText.replace(regex, '<mark>$1</mark>');
                node.innerHTML = highlightedText;
              }
            });
          }

          while (treeItem.parentElement) {
            treeItem = treeItem.parentElement;
            treeItem.expanded = true;
            treeItem.classList.remove('hidden');
            treeItem.classList.add('gray');
          }
        } else {
          //console.log('=> hide');
          treeItem.classList.add('hidden');
        }

      });
    }, 500);

  }

  renderIconsPermissions(item) {
    if (this.displayOptions?.includes('roles')) {
      if (item.permissions_merged) {
        this.selectIoRolesInstance = this.selectIoRolesInstance || document.createElement('select-io-roles');
        for (const [key, value] of Object.entries(item.permissions_merged)) {
          if (key === 'iora_roles') {
            if (!value) continue;
            return value.split(',').map(roleId => {
              const r = this.selectIoRolesInstance.items.find(r => {
                return r._id === roleId;
              });
              return html`
                <sl-tag variant="${r.variant}" size="small" class="hidden_label">
                  <m-icon name="${r.icon}" style="padding-right:4px"></m-icon>
                  <div>${Lang.lookup(r, 'name')}</div>
                </sl-tag>
              `;
            });
            
          }
        }
      }
    }
  }

  renderLoading() {
    this._log.debug('renderLoading');
    return html`<sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar>`;
  }

  renderTreeItems(item, items) {
    const childrens = items.filter(child => child.parentId === item._id);

    // order by nodeType and name, nodeType having noChilds last
    childrens.sort((a, b) => {
      const nodeType = this.nodeTypes[a.nodeType];
      if (!nodeType) {
        console.warn('Unknown nodeType', a.nodeType);
        return -1;
      }
      if (nodeType.noChilds) {
        return 1;
      } else if (!nodeType.noChilds) {
        return -1;
      } else {
        if (a.nodeType === b.nodeType) {
          return this.getNodeTitle(a).localeCompare(this.getNodeTitle(b));
        }
      }
    });


    const nodeType = this.nodeTypes[item.nodeType];

    if (!nodeType) {
      return html`
        <sl-tree-item 
          data-id="${item._id}"
          data-name="${item.name}"
          .item="${item}"
          title="${item._id}"
          value="${this.getNodeTitle(item)}"
          class="invalid"
        >
          <span class="icons_left" title="${item.name} (${item.nodeType})">${this.renderTreeItemIcon(item)}</span>
          <span class="node_title" title="${item.name} (${item.nodeType})" class="invalid">
            ${this.getNodeTitle(item)}
          </span>
          <span class="icons_right">${this.renderIconsPermissions(item)}</span>
          <span class="tree_menu" @click="${ev => this.showTreeMenu(ev)}"><m-icon name="more_vert"></m-icon></span>
          ${childrens.length > 0 ? html`${childrens.map(child => this.renderTreeItems(child, items))}` : ''}
        </sl-tree-item>
      `
    }

    const displayChildrenCount = nodeType.displayChildrenCount;

    if (nodeType.displayOnlyIfHasChildren && !childrens.length) {
      return '';
    }

    if (typeof item.enabled === 'boolean' && !item.enabled) {
      item.css ? item.css += ' disabled' : item.css = 'disabled';
    }

    return html`
      <sl-tree-item 
        draggable="${item.nodeType !== 'root'}"
        data-id="${item._id}"
        data-name="${item.name}"
        .item="${item}"
        title="${item._id}"
        value="${this.getNodeTitle(item)}"
        class="${item.css || ''} ${item.nodeType}"
        
        @contextmenu="${(ev) => this.showContextMenu(ev, item)}"
        @dragstart="${(ev) => this.dadOnDragStart(ev, item)}"
        @dragover="${(ev) => this.dadOnDragOver(ev, item)}"
        @drop="${(ev) => this.dadOnDrop(ev, item)}"
        @dblclick="${(ev) => this.showEditItem(ev)}"
        @sl-after-expand="${() => this.treeStoreExpanded()}"
        @sl-after-collapse="${() => this.treeStoreExpanded()}"
      >
        <span class="icons_left" title="${item.name} (${item.nodeType})">${this.renderTreeItemIcon(item)}</span>
        <span class="node_title" title="${item.name} (${item.nodeType})" class="${item.nodeType}">
          ${this.getNodeTitle(item)}
          ${displayChildrenCount ? html` (${childrens.length})` : ''}
        </span>
        <span class="icons_right">${this.renderIconsPermissions(item)}</span>
        <span class="tree_menu" @click="${ev => this.showTreeMenu(ev)}"><m-icon name="more_vert"></m-icon></span>
        ${childrens.length > 0 ? html`${childrens.map(child => this.renderTreeItems(child, items))}` : ''}
      </sl-tree-item>
    `;
  }

  renderTree() {
    this._log.debug('renderTree');
    if (this.loading) return;
    if (this.items?.length) {
      const rootItems = this.items ? this.items.filter(item => !item.parentId || !this.items.some(i => i._id === item.parentId)) : [];
      return html`
        <div style="position:relative;">
          <sl-tree selection="single" @sl-selection-change="${this.onTreeSelectionChange}">
            ${rootItems.map(item => this.renderTreeItems(item, this.items))}
          </sl-tree>
        </div>
      `
    } else {
      return html`<div>C'est vide !</div>`;
    }
  }

  renderDisplayMenu() {
    const options = [ 
      { id:'roles', micon:'folder_supervised' },
      //{ id:'permissions', micon:'folder_supervised' },
      { id:'validations', micon:'verified' }
    ]

    return options.map(option => {
      return html`
        <sl-option value="${option.id}">
          <m-icon slot="prefix" name="${option.micon}"></m-icon>
          ${this._tl('display.'+option.id)}
        </sl-option>
      `;
    });
  }

  toggleDisplayOptions(ev) {  
    this.displayOptions = ev.target.value;
  }

  getButtonAddStatus() {
    //@FIXME
    return false;
    /*
    if (!this.selectedItem) return false;
    if (this.nodeTypes[this.selectedItem.nodeType].noChilds) return true;
    return false;
    */
  }

  renderToolbar() {
    return html`
      <div class="search">
        <sl-input size="small" name="eq" @sl-input="${this.searchOnInput}" clearable .value="${this.q || ''}">
          <m-icon name="search" slot="suffix"></m-icon>
        </sl-input>
      </div>
      <div class="toolbar">
        
        <div>
          <!--
          <sl-button size="small" name="toggleCollapse" @click="${this.toggleCollapse}">
            <div>${this._tl('expandAll')}</div>
            <m-icon slot="prefix" name="unfold_more" size="big" title="${this._tl('expandAll')}">
          </sl-button>
          -->
        </div>

        <div>
          <sl-select
            max-options-visible="1"
            placeholder="${this._tl('display.title')}"
            multiple
            size="small" 
            @sl-change=${this.toggleDisplayOptions}>${this.renderDisplayMenu()}
          </sl-select>

          <sl-dropdown>
            <sl-button slot="trigger" size="small" ?disabled="${this.getButtonAddStatus()}">
              <div>${this._tl('add')}</div>
              <m-icon slot="prefix" name="add" size="big" title="${this._tl('add')}">
            </sl-button>
            <customers-tree-menu .parent=${this}></customers-tree-menu>
          </sl-dropdown>
        </div>

      </div>
    `
  }

  render() {
    this._log.info('render', 'items', this.items?.length);

    return html`
      ${this.renderToolbar()}<br/>
      ${this.renderLoading()}
      ${this.renderTree()}

      <customers-tree-menu .parent=${this} floating></customers-tree-menu>

      <modal-dialog id="modal-customer-confirm-move" label="Déplacer l'élément">
        <m-icon name="move_item" slot="micon"></m-icon>
        Etes vous sûr(e) de vouloir déplacer l'élément ?<br/><br/>
        <div class="flex">
          <box-styled id="dragged"></box-styled>
          <m-icon name="text_select_move_forward_word"></m-icon>
          <box-styled id="targeted"></box-styled>
        </div>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.doItemMove}">Déplacer</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-customer-confirm-move-kc" label="Confirmation">
        <br/>  
        <p>
          <sl-radio-group label="bla" size="small"  value="move">
            <br/>
            <sl-radio size="small" value="move">Déplacer le(s) utilisateur(s)</sl-radio><br/>
            <sl-radio size="small" value="link">Le(s) rajouter en tant que membre</sl-radio>
          </sl-radio-group>
        </p>
        <br/>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.doItemMoveKc}">Déplacer</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-customer-confirm-archive" label="Archiver l'élément">
        <m-icon name="move_item" slot="micon"></m-icon>
        Etes vous sûr(e) de vouloir archiver l'élément ?<br/><br/>
        <box-styled><sl-switch size="small">Oui, je confirme</sl-switch></box-styled>
        <span class="warning"></span><br/>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="danger" @click="${this.doItemArchive}">Archiver</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-customer-confirm-delete" label="Supprimer l'élément">
        <m-icon name="delete_forever" slot="micon"></m-icon>
        Etes vous sûr(e) de vouloir supprimer l'élément ?<br/><br/>
        <box-styled><sl-switch size="small">Oui, je confirme</sl-switch></box-styled><br/>
        <span class="warning"></span>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="danger" @click="${this.doItemDelete}" disabled>Supprimer</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-customer-confirm-restore" label="Restaurer l'élément">
        <m-icon name="restore_from_trash" slot="micon"></m-icon>
        Etes vous sûr(e) de vouloir restaurer l'élément ?<br/><br/>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.doItemRestore}">Restaurer</sl-button>
      </modal-dialog>

    `;
  }
}

customElements.define('customers-tree', CustomersTree);